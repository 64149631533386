import React, {useState} from "react";
import {Input} from "../shared/input";
import {PlannerColorPicker} from "../shared/color-picker";
import {Button} from "../shared/button";
import {nanoid} from "nanoid";
import {Field} from "../shared/field";
import {Popover} from "../popover";
import {planner_defaults} from "../../pages/blog/release-planner";


export function RoadmapperAddCategory({onAddCategory, existing = {names: []}}) {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [section_id, setSectionId] = useState('');

    function handleCreate() {
        onAddCategory({name, desc, section_id, feature_ids: [], id: nanoid(10)});
        setName('');
        setSectionId('');
        setDesc('');
        setIsOpen(false);
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter'&&name.length>0) {
handleCreate();

        }
    }
    const can_create = name.length > 0 && existing.names.indexOf(name) === -1;
    const content = <div className="space-y-2 w-96 px-4 py-3 rounded-md bg-white max-w-md">
        <div>
            <Field label="Name">
                <Input autoFocus handleKeyDown={handleKeyDown} placeholder="Members" onChange={(v) => {
                    setName(v);
                }} value={name}/>
            </Field>
        </div>
        <div>
            <Field label="Description">
                <Input handleKeyDown={handleKeyDown} placeholder="Grouping of features.." onChange={(v) => {
                    setDesc(v);
                }} value={desc}/>
            </Field>
        </div>
        <div className="flex items-center space-x-4">
            <div>
                <Button disabled={!can_create} onClick={() => {
                    handleCreate();
                }}/>
            </div>
        </div>
    </div>
    return <Popover isOpen={isOpen} setOpen={(sio)=>setIsOpen(sio)} content={content}>
        <div onClick={()=>setIsOpen(!isOpen)}
             className={`text-blue-600 text-xs font-semibold cursor-pointer hover:opacity-60 transition-opacity`}>
            <div >
                {!isOpen?"Add Category":"Cancel"}
            </div>
        </div>
    </Popover>
}