import React, {useState} from "react";
import {CheckIcon, ChevronDownIcon, ChevronLeftIcon} from "@heroicons/react/outline";
import {RoadmapperAddFeature} from "./add-feature";

/*
<tr className="border-b border-gray-200">
                <td width={'15%'} className="text-right">
                    <span className="text-xs text-gray-400">CW</span>
                </td>
                {phase_ids.map((pid) => {
                    const dt = phase_data[pid];
                    const cols = Array.from(Array(dt.weeks).keys());
                    return cols.map((col,col_index)=>{
                        counter++;
                        return <td className="text-left text-xs text-gray-400 pl-5" key={`${pid}-${col}`}>

                        </td>
                    })
                })}
            </tr>
 */
function THCell({children, border_r, border_b, width, col_span = 1, subtitle, title, desc}) {
    return <th width={width} colSpan={col_span}
               className={`text-left px-5 py-3 border-gray-200 ${border_b ? "border-b" : ""} ${border_r ? "border-r" : ""}`}>
        {title && <div className="text-lg font-bold text-gray-800">{title}</div>}
        {subtitle && <div className="text-sm -mt-0.5 font-normal text-gray-600">{subtitle}</div>}
        {desc && <div className="text-xs mt-1 font-normal text-gray-500">{desc}</div>}
        {children}
    </th>
}

function CellIcon({children}) {
    return <div>
        <div className="h-5 w-5">
            {children}
        </div>
    </div>
}

function BCell({width, title, desc, onClick, col_span, interactive, border_r, border_b, children}) {
    return <td onClick={() => {
        if (onClick) {
            onClick();
        }
    }} width={width} colSpan={col_span}
               className={`px-5 py-1.5 ${interactive ? "hover:bg-gray-100 cursor-pointer" : ""} relative border-gray-200 ${border_b ? "border-b" : ""} ${border_r ? "border-r" : ""}`}>
        {title && <div className="text-base font-bold text-gray-800">{title}</div>}
        {desc && <div className="text-sm font-normal text-gray-500">{desc}</div>}
        {children}
    </td>
}

function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}

function getCWStart(sd) {
    let sd1 = sd || "08-01-2022";

    const wn = getWeekNumber(new Date(sd1));

    return wn[1];
}

const options = {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'};

function ShowStartDate({start_date = '08-01-2022', cw_start}) {
    return <div className="text-sm text-gray-600">
        Starts in the week of {new Date(start_date).toLocaleDateString('en-us', options)} ({cw_start})
    </div>
}

function getPhaseFeatureRollup(phaseid, features, phase_ids) {
    const len = features.length;
    if (!len) {
        return [0, 0];
    }
    const phase_index = phase_ids.indexOf(phaseid);
    let counter = 0;
    features.forEach(([fid, feat]) => {
        if (feat.target_phase_id) {
            const index_feature_phase = phase_ids.indexOf(feat.target_phase_id);
            if (index_feature_phase <= phase_index) {
                counter++;
            }
        }
    })
    return [counter, len];
}

function buildOrder(category_ids, category_data) {
    return category_ids.map(cid => {
        return {
            name: category_data[cid].name,
            id: cid
        }
    }).sort((a, b) => a.name > b.name ? 1 : -1).map(it => it.id);
}

function shouldShowCheck(fdata, pid, phase_ids) {
    if (!fdata.target_phase_id) {
        return false;
    }
    const index_base = phase_ids.indexOf(pid);
    const index_target = phase_ids.indexOf(fdata.target_phase_id);
    return fdata.target_phase_id === pid || (index_target < index_base);
}

function ExpandToggleAll({collapse_all, toggle}) {
    return <div onClick={() => toggle()}
                className={`text-blue-600 text-xs font-semibold cursor-pointer hover:opacity-60 transition-opacity`}>
        <div>
            {collapse_all ? "Collapse all" : "Expand all"}
        </div>
    </div>
}

export function FeatureMatrix({
                                  phase_ids,
                                  af_props,
                                  setFeatureTargetPhase,
                                  start_date,
                                  onDelete,
                                  onEdit,
                                  add_category,
                                  add_feature,
                                  phase_data,
                                  category_ids,
                                  category_data,
                                  feature_data
                              }) {
    const [expanded, setExpanded] = useState({});

    function handleToggleCat(cid) {
        let ne = {...expanded};
        if (ne[cid]) {
            delete ne[cid];
        } else {
            ne[cid] = true;
        }
        setExpanded(ne);
    }

    function handleToggleAll() {
        const exp = Object.keys(expanded).length;
        if (exp > 0) {
            setExpanded({});
        } else {
            let nex = {};
            category_ids.forEach(cid => {
                nex[cid] = true;
            })
            setExpanded(nex);
        }
    }

    const total_columns = 1 + (Object.values(phase_data).map(it => it.weeks).reduce((partialSum, a) => partialSum + a, 0));

    const cw_start = getCWStart(start_date);
    let counter = 0;

    const ordered = buildOrder(category_ids, category_data);

    return <div>
        <div className="py-1">
            <ShowStartDate cw_start={cw_start} start_date={start_date}/>
        </div>
        <table className="table-auto w-full border border-gray-200 rounded-xl">
            <thead>
            <tr>
                <THCell border_r border_b width={'20%'} subtitle={`Features \\ Phases`}/>
                {phase_ids.map((pid, phaseindex) => {
                    const dt = phase_data[pid];
                    return <THCell border_r={phaseindex !== phase_ids.length} border_b
                                   width={`${(80 / phase_ids.length)}%`} title={dt.name} subtitle={""} desc={dt.desc}
                                   key={pid} {...dt}/>
                })}
            </tr>
            </thead>
            <tbody>
            {ordered.map((cid, catindex) => {
                const cdt = category_data[cid];
                const cat_features = Object.entries(feature_data)
                    .filter(a => a[1].category_id === cid)
                    .sort((a, b) => a[1].name > b[1].name ? 1 : -1);
                return <>
                    <tr key={cid}>
                        <BCell interactive={true} border_b border_r width={'20%'}>
                            <input className="-my-px py-px px-1 -mx-1 text-base font-semibold rounded-md"
                                   value={cdt.name} onChange={(e) => {
                                onEdit('category-name', cid, e.target.value);
                            }}/>
                            {expanded[cid] &&
                            <div className=""><input className="-my-px py-px px-1 -mx-1 text-sm rounded-md"
                                                     value={cdt.desc} onChange={(e) => {
                                onEdit('category-desc', cid, e.target.value);
                            }}/></div>}
                            <div onClick={() => handleToggleCat(cid)}
                                 className="underline absolute top-0 right-2 bottom-0 flex items-center pt-0.5 text-xs text-gray-500">
                                <div className="text-gray-500 w-4 h-4">
                                    {!expanded[cid] ? <ChevronLeftIcon/> : <ChevronDownIcon/>}
                                </div>
                            </div>
                        </BCell>
                        {phase_ids.map((pid, cellindex) => {
                            const cols = Array.from(Array(phase_data[pid].weeks).keys());
                            const rollup = getPhaseFeatureRollup(pid, cat_features, phase_ids);
                            const percent = (rollup[0] / rollup[1]) * 100;
                            let cc = "";
                            if (percent > 95) {
cc = "text-green-700";
                            } else if(percent>50) {
                                cc = "text-gray-800";
                            } else if(percent>35) {
                                cc = "text-gray-600";
                            } else if(percent>20) {
                                cc = "text-gray-500";
                            } else {
                                cc = "text-gray-400";
                            }
                            return <BCell border_r border_b key={`${cellindex}`}>
                                <span className={`${cc} text-sm`}>({rollup[0]}/{rollup[1]})</span>
                            </BCell>
                        })}
                    </tr>
                    {expanded[cid] && cat_features.map(([fid, fdata]) => {
                        return <tr key={`${cid}-${fid}`}>
                            <BCell border_r border_b>
                                <span className="pl-5">
                                    <input className="-my-px py-px px-1 -mx-1 text-gray-700 text-sm rounded-md"
                                           value={fdata.name} onChange={(e) => {
                                        onEdit('feature-name', fid, e.target.value);
                                    }}/>
                                </span>
                            </BCell>
                            {phase_ids.map((pid, cellindex) => {
                                const cols = Array.from(Array(phase_data[pid].weeks).keys());
                                const show_check = shouldShowCheck(fdata, pid, phase_ids);
                                return <BCell border_r border_b onClick={() => setFeatureTargetPhase(fid, pid)}
                                              interactive key={`${cellindex}`}>
                                    <CellIcon>
                                        {show_check && <CheckIcon className="text-green-700"/>}
                                    </CellIcon>
                                </BCell>
                            })}
                        </tr>
                    })}
                    {expanded[cid] && <tr>
                        <td colSpan={1 + (phase_ids.length)} className="pl-10 py-2.5 border-gray-200 border-b">
                            <RoadmapperAddFeature {...af_props} cat_id={cid}/>
                        </td>
                    </tr>}
                </>
            })}
            <tr>
                <BCell colSpan={1 + (phase_ids.length)} width={'15%'}>
                    <div className="py-2">
                        {add_category}
                    </div>
                </BCell>
            </tr>
            </tbody>
        </table>
        <div className="py-2">
            <ExpandToggleAll toggle={handleToggleAll} collapse_all={Object.keys(expanded).length > 0}/>
        </div>
    </div>
}