import React, {useState} from "react";
import {Input} from "../shared/input";
import {PlannerColorPicker} from "../shared/color-picker";
import {Button} from "../shared/button";
import {nanoid} from "nanoid";
import {Field} from "../shared/field";
import {Popover} from "../popover";
import {planner_defaults} from "../../pages/blog/release-planner";

function getDefaultColor(existing_colors) {
    let c = '';
    for (let i = 0; i < planner_defaults.colors.length; i++) {
        const color = planner_defaults.colors[i];
        if (existing_colors.indexOf(color) === -1) {
            c = color;
            break;
        }
    }
    return c;
}

export function RoadmapperAddSection({onAddSection, existing = {colors: [], names: []}}) {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [color, setColor] = useState(getDefaultColor(existing.colors||[]));
    if (existing.colors.length > 7) {
        return null;
    }
    function handleCreate() {
        let c = color;
        onAddSection({name, desc, color, id: nanoid(10)});
        setName('');
        setDesc('');
        setIsOpen(false);
        const final_color_arr = [...existing.colors, c];
        setColor(getDefaultColor(final_color_arr));
    }
    function handleKeyDown(e) {
        if (e.key === 'Enter'&&name.length>0) {
         handleCreate();
        }
    }
    const can_create = color !== '' && name.length > 0 && existing.names.indexOf(name) === -1;
    const content = <div className="space-y-2 w-96 px-4 py-3 rounded-md bg-white max-w-md">
        <div>
            <Field label="Name">
                <Input autoFocus handleKeyDown={handleKeyDown} placeholder="Unaverse" onChange={(v) => {
                    setName(v);
                }} value={name}/>
            </Field>
        </div>
        <div>
            <Field label="Description">
                <Input handleKeyDown={handleKeyDown} placeholder="..." onChange={(v) => {
                    setDesc(v);
                }} value={desc}/>
            </Field>
        </div>
        <div className="flex items-center space-x-4">
            <div className="flex items-center flex-grow">
                <PlannerColorPicker active={color} onSelect={(new_color) => setColor(new_color)}
                                    blocked={existing.colors}/>
            </div>
            <div>
                <Button disabled={!can_create} onClick={() => {
                    handleCreate();

                }}/>
            </div>
        </div>
    </div>
    return <Popover isOpen={isOpen} setOpen={(sio)=>setIsOpen(sio)} content={content}>
        <div onClick={()=>setIsOpen(!isOpen)}
             className={`text-blue-600 text-xs font-semibold cursor-pointer hover:opacity-60 transition-opacity`}>
            <div >
                {!isOpen?"Add Section":"Cancel"}
            </div>
        </div>
    </Popover>
}