import React from "react";
import {RoadmapperAddBenefitGroup} from "./add-benefit-group";
import {RoadmapperAddBenefit} from "./add-benefit";
import {FeatureSelector} from "./feature-selector";

function filterItems(obj, bid) {
    if (!obj.benefit_ids) {
        return [];
    }
    return obj.benefit_ids.filter(id => {
        return obj.benefit_data[id].benefit_group_id === bid;
    })
}

function MTitle({children}) {
    return <div className="text-xs uppercase pb-2 font-semibold text-gray-600 tracking-wide">
        {children}
    </div>
}

export function BenefitManager({bf_props, onEdit, data}) {

    return <div className="pb-8">
        {(data.benefit_group_ids || []).map(bid => {
            const dt = data.benefit_group_data[bid];
            const benefits = filterItems(data, bid);
            return <div className="border p-5 border-gray-200 rounded-lg" key={bid}>
                <div>
                    <input className="-my-px w-full py-px px-1 -mx-1 text-3xl font-bold rounded-md"
                           value={dt.name} onChange={(e) => {
                        onEdit('benefit_group-name', bid, e.target.value);
                    }}/>
                    <div className="pt-1 pb-4 max-w-4xl">
                    <textarea className="-my-px w-full py-px px-1 -mx-1 text-base text-gray-600 rounded-md"
                              value={dt.desc} onChange={(e) => {
                        onEdit('benefit_group-desc', bid, e.target.value);
                    }}/>
                    </div>
                </div>
                <div className="gap-2 grid">
                    {benefits.map((bene_id, index) => {
                        const bdt = data.benefit_data[bene_id];
                        return <div className="grid grid-cols-5 gap-8">
                            <div className="col-span-2">
                                {index === 0 && <MTitle>Benefits</MTitle>}
                                <div className={"text-lg "}>
                                    <input className="-my-px font-semibold w-full py-px px-1 -mx-1 rounded-md"
                                           value={bdt.name} onChange={(e) => {
                                        onEdit('benefit-name', bene_id, e.target.value);
                                    }}/>
                                </div>
                                <div className="-mt-0.5">
                                          <textarea placeholder="Describe the value or the benefit to the user.."
                                              className="-my-px w-full py-px px-1 -mx-1 text-sm text-gray-600 rounded-md"
                                              value={bdt.desc} onChange={(e) => {
                                              onEdit('benefit-desc', bene_id, e.target.value);
                                          }}/>
                                </div>
                            </div>
                            <div className="col-span-3">
                                {index === 0 && <MTitle>Features</MTitle>}
                                <div>
                                    <FeatureSelector selected={bdt.feature_ids||[]} onSelectFeature={(id)=>{
                                        bf_props.onSelectFeature(id,bene_id);
                                    }} features={bf_props.features} />
                                </div>
                            </div>
                        </div>
                    })}
                    <div>
                        <RoadmapperAddBenefit onAddBenefit={bf_props.onAddBenefit} benefit_group_id={bid}
                                              benefit_names={{names: []}}/>
                    </div>
                </div>
            </div>
        })}
        <div className="pt-3">
            <RoadmapperAddBenefitGroup {...bf_props} />
        </div>
    </div>
}