import React, {useEffect, useState} from 'react';
import {RoadmapperAddSection} from "../../components/roadmapper/add-section";
import {RoadmapperAddPhase} from "../../components/roadmapper/add-phase";
import {RoadmapperAddFeature} from "../../components/roadmapper/add-feature";
import {RoadmapperAddCategory} from "../../components/roadmapper/add-category";
import {FeatureMatrix} from "../../components/roadmapper/feature-matrix";
import {Button} from "../../components/shared/button";
import {downloadAsJSON, handleLoadedPlan} from "../../utilities/planner";
import {ScheduleUploader} from "../../components/planner/schedule-uploader";
import Page from "../../components/page";
import Header from "../../components/header";
import {RoadmapperAddBenefitGroup} from "../../components/roadmapper/add-benefit-group";
import {BenefitManager} from "../../components/roadmapper/benefit-manager";

const m_section = {
    name: "string",
    desc: "string",
    color: "hex"
};

const m_feature = {
    name: "string",
    desc: "string",
    category_id: "string",
    target_phase_id: "string",
    flows: "[string]",
    capabilities: "[string]",
};

const m_category = {
    name: "string",
    desc: "string",
    section_id: "string",
    feature_ids: "[string]"
};

const m_flow = {
    name: "string",
    desc: "string",
};

const m_phase = {
    name: "string",
    desc: "string",
    weeks: "integer",
    subtitle: "string"
};

const m_capability = {
    name: "string",
    desc: "string",
    feature_id: "string",
};

const m_benefit = {
    name: "string",
    desc: "string",
    feature_ids: "[string]",
    benefit_group_ids: "[string]"
};

const m_benefit_group = {
    name: "string",
    desc: "string"
};

const base_spec = {
    start_date: "01-08-2022",

    section_ids: [],
    section_data: {},

    phase_ids: [],
    phase_data: {}, // alpha, beta

    category_ids: [],
    category_data: {}, // members, groups

    feature_ids: [],
    feature_data: {}, // member education

    capability_ids: [],
    capability_data: {}, // add your schools from a db, import from linkedin

    flow_ids: [],
    flow_data: {}, // add member to a group

    benefit_group_ids: [],
    benefit_group_data: {}, // Modern Member Data Management

    benefit_ids: [],
    benefit_data: {}, // Search for members by their email, name, or any other profile attribute

    value_ids: [],
    value_data: {}, // real world value for a person...


    phase_feature_data: {},
};

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

function SwitchBtn({text,active,onClick}) {
    const bg = active ? "bg-blue-600" : "bg-white";
    const bg_hover = active ? "" : "bg-gray-100";
    const text_color = active ? "text-white" : "text-gray-600";
    return  <Button size="sm" bg={bg} text_color={text_color} bg_hover={bg_hover}
                    onClick={onClick} text={text}/>
}

export default function RoadmapPlanner() {
    const [state, setState] = useState(() => {
        return {...base_spec};
    });
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('feature-matrix');

    useEffect(function () {
        let url = new URL(window.location.href);
        let has_config = url.search.includes('?config=') && url.search.includes('.json');
        if (has_config) {
            const config_url = url.search.split('?config=')[1];
            if (validURL(config_url)) {
                setLoading(true);
                const test = "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/static%2Frelease-config-test.json?alt=media";

                fetch(config_url)
                    .then(response => {
                        response.json().then(obj => {
                            setLoading(false);
                            setState(obj);
                        });
                        //window.location.href = response.url;
                    })
                    .catch(error => {
                        setLoading(false);
                        alert('Could not load file')
                    });
            }

        }
    }, []);

    function handleAddSection({name, color, desc, id}) {
        let updated = {...state};
        const new_item = {
            name, color, desc, id
        };
        updated.section_ids.push(id);
        updated.section_data[id] = new_item;
        setState(updated);
    }

    function handleAddFeature({name, desc, category_id, target_phase_id, id, flow_ids = []}) {
        let updated = {...state};
        const new_item = {
            name, desc, category_id, target_phase_id, id, flow_ids
        };
        updated.feature_ids.push(id);
        updated.feature_data[id] = new_item;
        setState(updated);
    }

    function handleAddPhase({name, desc, weeks, subtitle, id}) {
        let updated = {...state};
        const new_item = {
            name, desc, weeks, subtitle, id
        };
        updated.phase_ids.push(id);
        updated.phase_data[id] = new_item;
        setState(updated);
    }

    function handleSelectFeature(feature_id, benefit_id) {
        let updated = {...state};
        const b_data = updated.benefit_data[benefit_id];
        if (!b_data.feature_ids) {
            updated.benefit_data[benefit_id].feature_ids = [];
        }
        const f_index = updated.benefit_data[benefit_id].feature_ids.indexOf(feature_id);
        if(f_index===-1) {
            updated.benefit_data[benefit_id].feature_ids.push(feature_id);
        } else {
            updated.benefit_data[benefit_id].feature_ids.splice(f_index,1);
        }
        setState(updated);
    }

    function handleAddBenefit({name, desc, id, benefit_group_id}) {
        let updated = {...state};
        const new_item = {
            name, desc, id, benefit_group_id, feature_ids: []
        };
        if (!updated.benefit_data) {
            updated.benefit_ids = [];
            updated.benefit_data = {};
        }
        updated.benefit_ids.push(id);
        updated.benefit_data[id] = new_item;
        setState(updated);
    }

    function handleAddBenefitGroup({name, desc, id}) {
        let updated = {...state};
        const new_item = {
            name, desc, id
        };
        if (!updated.benefit_group_data) {
            updated.benefit_group_ids = [];
            updated.benefit_group_data = {};
        }
        updated.benefit_group_ids.push(id);
        updated.benefit_group_data[id] = new_item;
        setState(updated);
    }

    function handleAddCategory({name, desc, section_id, feature_ids = [], id}) {
        let updated = {...state};
        const new_item = {
            name, desc, section_id, feature_ids, id
        };
        updated.category_ids.push(id);
        updated.category_data[id] = new_item;
        setState(updated);
    }

    function handleEdit(type, id, val, ex) {
        let u = {...state};
        if (type === 'category-name') {
            u.category_data[id].name = val;
        } else if (type === 'category-desc') {
            u.category_data[id].desc = val;
        } else if (type === 'feature-name') {
            u.feature_data[id].name = val;
        } else if (type === 'benefit-name') {
            u.benefit_data[id].name = val;
        } else if (type === 'benefit_group-name') {
            u.benefit_group_data[id].name = val;
        } else if (type === 'benefit-desc') {
            u.benefit_data[id].desc = val;
        } else if (type === 'benefit_group-desc') {
            u.benefit_group_data[id].desc = val;
        }
        setState(u);
    }

    function handleDelete(type, id, val, ex) {
        let u = {...state};
        let ind;
        if (type === 'category') {
            ind = u.category_ids.indexOf(id);
            u.category_ids = u.category_ids.splice(ind);
            delete u.category_data[id];
        }
        setState(u);
    }

    const existing_sections = {
        names: Object.values(state.section_data).map(sd => sd.name),
        colors: Object.values(state.section_data).map(sd => sd.color)
    };

    const categories = [
        {id: "", label: "--"}
    ].concat(Object.entries(state.category_data).map(([cid, cdata]) => {
        return {
            id: cid,
            label: cdata.name
        }
    }))

    const features_list = (state.feature_ids || []).map(fid => {
        const fdt = state.feature_data[fid];

        let name;
        let cat_name;
        if(fdt.category_id) {
            cat_name = state.category_data[fdt.category_id].name;
        }
        if(cat_name) {
            name = `[${cat_name}] `;
        }
        name += fdt.name;
        return {
            hidden: !fdt.category_id,
            id: fid,
            name: name
        }
    }).filter(a=>!a.hidden);

    const af_props = {
        categories,
        features: {
            list: features_list,
            raw: state.feature_data
        },
        onAddFeature: handleAddFeature,
        existing: {names: Object.values(state.feature_data).map(sd => sd.name)}
    }

    const bf_props = {
        onAddBenefit: handleAddBenefit,
        onAddBenefitGroup: handleAddBenefitGroup,
        onSelectFeature: handleSelectFeature,
        features: {
            list: features_list,
            raw: state.feature_data
        },
        benefit_group_names: {names: Object.values(state.benefit_group_data || []).map(sd => sd.name)},
        benefit_names: {names: Object.values(state.benefit_data || []).map(sd => sd.name)},
    };

    const af = <RoadmapperAddFeature {...af_props} />;
    const ac = <RoadmapperAddCategory onAddCategory={handleAddCategory.bind(this)}
                                      existing={{names: Object.values(state.category_data).map(sd => sd.name)}}/>;

    let debug = false;

    let content;

    if (mode === 'benefits') {
        content = <div>
            <BenefitManager onDelete={handleDelete.bind(this)} onEdit={handleEdit.bind(this)} data={state}
                            bf_props={bf_props}/>
        </div>;
    } else {
        content = <div>

            <FeatureMatrix af_props={af_props} setFeatureTargetPhase={(fid, pid) => {
                let u = {...state};
                u.feature_data[fid].target_phase_id = pid;
                setState(u);
            }} onDelete={handleDelete.bind(this)} onEdit={handleEdit.bind(this)} add_feature={af}
                           add_category={ac} {...state} />
        </div>;
    }

    return <Page menu>
        <Header title_size="text-4xl" title='Roadmap Planner'/>
        <div className="container relative pb-8 mx-auto">

            <div className="py-8 flex items-center justify-center space-x-4">
                <SwitchBtn active={mode!=='benefits'} onClick={() => setMode('matrix')} text={"Feature Matrix"}/>
                <SwitchBtn active={mode==='benefits'} onClick={() => setMode('benefits')} text={"Benefits"}/>
            </div>

            {content}

            <div className="pt-4 flex space-x-4 items-center">
                <ScheduleUploader handleLoadedPlan={(np) => {
                    setState({...np});
                }}/>

                <Button size="sm" bg="bg-white" text_color="text-gray-600" bg_hover="bg-gray-100"
                        onClick={() => downloadAsJSON(state, 'roadmap-planner.json')} text={"Save as JSON"}/>

                <RoadmapperAddSection onAddSection={handleAddSection.bind(this)} existing={existing_sections}/>
                <RoadmapperAddPhase onAddPhase={handleAddPhase.bind(this)}
                                    existing={{names: Object.values(state.phase_data).map(sd => sd.name)}}/>

            </div>

            {debug && <pre className="text-xs bg-gray-200 font-medium text-gray-700 overflow-y-scroll text-left"
                           style={{maxHeight: '50vh'}}>{JSON.stringify(state, undefined, 2)}</pre>}


        </div>
    </Page>
}