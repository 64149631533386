import React from "react";
import {EntitySelector} from "../planner/entity-selector";
import {Popover} from "../popover";
import {Entity} from "../planner/entity";

function FeaturesList({features,selected=[]}) {
    return <div className="gap-2 flex flex-wrap cursor-pointer hover:bg-gray-50 -m-1 p-1 rounded-lg">
        {!selected.length&&<div className="text-gray-400">Add a feature</div>}
        {selected.map(feat_id=>{
            const feat_data = features.list[features.list.findIndex(a=>a.id===feat_id)];
            return <div className="text-sm whitespace-nowrap font-medium text-gray-600 bg-gray-100 tracking-tight rounded-md px-1 py-px" key={feat_id}>
                {feat_data.name}
            </div>
        })}
    </div>
}

export function FeatureSelector({features,onSelectFeature,selected=[]}) {
    const content = <div>
        <EntitySelector
            entities={[
                {
                    header: 'Supporting features',
                    items: features.list
                }
            ]}
            layout='text'
            can_add={false}
            selected={selected}
            onSelect={(id, data) => {
                onSelectFeature(id,data);
                console.log('on select')
            }}
            onAddPerson={(np) => {

            }}
        />
    </div>;

    return <Popover content={content}>
        <FeaturesList selected={selected} features={features} />
    </Popover>
}