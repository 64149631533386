import React, {useState} from "react";
import {Input} from "../shared/input";
import {PlannerColorPicker} from "../shared/color-picker";
import {Button} from "../shared/button";
import {nanoid} from "nanoid";
import {Field} from "../shared/field";
import {Popover} from "../popover";
import {planner_defaults} from "../../pages/blog/release-planner";
import {Dropdown} from "../shared/dropdown";


export function RoadmapperAddFeature({onAddFeature, cat_id, categories, existing = {names: []}}) {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [category_id, setCategoryId] = useState(cat_id||'');
    const [target_phase_id, setTargetPhaseId] = useState('');

    function handleCreate() {
        onAddFeature({name, desc, category_id,target_phase_id, id: nanoid(10)});
        setName('');
        setCategoryId(cat_id||'');
        setTargetPhaseId('');
        setDesc('');
        setIsOpen(false);
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter'&&name.length>0) {
            handleCreate();
        }
    }
    const can_create = name.length > 0;
    const content = <div className="space-y-2 w-96 px-4 py-3 rounded-md bg-white max-w-md">
        <div>
            <Field label="Name">
                <Input autoFocus handleKeyDown={handleKeyDown} placeholder="Profile Preview" onChange={(v) => {
                    setName(v);
                }} value={name}/>
            </Field>
        </div>
        <div>
            <Field label="Description">
                <Input handleKeyDown={handleKeyDown} placeholder="..." onChange={(v) => {
                    setDesc(v);
                }} value={desc}/>
            </Field>
        </div>
        <div>
            <Field label="Category">
                <Dropdown selected={category_id} items={categories} handleSelect={(p) => {
                   setCategoryId(p);
                }} fill/>
            </Field>
        </div>
        <div className="flex items-center space-x-4">
            <div>
                <Button disabled={!can_create} onClick={() => {
                    handleCreate();
                }}/>
            </div>
        </div>
    </div>
    return <Popover isOpen={isOpen} setOpen={(sio)=>setIsOpen(sio)} content={content}>
        <div onClick={()=>setIsOpen(!isOpen)}
             className={`text-blue-600 text-xs font-semibold cursor-pointer hover:opacity-60 transition-opacity`}>
            <div >
                {!isOpen?"Add Feature":"Cancel"}
            </div>
        </div>
    </Popover>
}